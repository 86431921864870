import * as React from 'react';

const StepBar = (props) => {
  return (
    <div className="steps-nav">
      <ul>
        <li className={props.step === 'UploadYourArt' ? 'current' : ''}>Upload your art</li>
        <li className={props.step === 'SecurePreview' ? 'current' : ''}>Secure preview</li>
        <li className={props.step === 'ReceivePreview' ? 'current' : ''}>Receive preview</li>
      </ul>
    </div>
  )
}

export default StepBar;
