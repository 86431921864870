import React, { useState, useEffect } from "react";
import SelectMerchProduct from "./select-merch-product";
import { products_array, tooltips_array } from "../../services/mock";
import axios from 'axios';

const BASE_URL = process.env.GATSBY_BASE_URL;


export default function SelectMerchCarousel({ carousel, active, handleShowNextButton, sendGeneratedSku, registeredUserId }) {
  const [isSaved, setIsSaved] = useState(false);
  const [productsObject, setProductsObject] = useState({});

  useEffect(() => {
    if (active && !isSaved && Object.keys(productsObject).length > 30 && registeredUserId) {
      axios.post(`${BASE_URL}/api/v1/carousels/baselsave`, {
        carousel_uID: carousel.carousel_uID,
        carousel_created_time: Date.now(),
        carousels: productsObject,
        thumbnail: carousel.thumbnail,
        upscaled: carousel.upscaled,
        userID: registeredUserId,
      });

      setIsSaved(true);
    }
  }, [productsObject, active, isSaved, registeredUserId])

  useEffect(() => {
    if (active) {
      if (Object.keys(productsObject).length > 30) {
        sendGeneratedSku(productsObject);
        handleShowNextButton(true);
      } 
      // else {
      //   handleShowNextButton(false);
      // }
    }
  }, [active, productsObject])

  return (
    <div className={`item ${carousel.carousel_uID}`}>
      <div className="container-inn">
        <div className="row" id="product-carousel-slot">
          {products_array.map((key, i) => (
            <SelectMerchProduct
              key={i}
              productKey={key}
              productLabel={tooltips_array[i]}
              dataId={`${carousel.carousel_uID}_${key}`}
              carousel={carousel}
              active={active}
              updateProductsObject={(product) => {
                setProductsObject((productsObject) => {
                  return {
                    ...productsObject,
                    [key]: product,
                  };
                })
              }}
              logo={carousel.logo}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
