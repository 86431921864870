import * as React from "react";
import { withPrefix } from "gatsby";
import avatarArray from './avatar_data.json';
import Swal from 'sweetalert2';
import { sendBoothAIRequest, sendBaselBoothEmail } from "../../services/apiCalls";

function waitFiveSeconds() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, 5000);
  });
}

const getTaskStatus = async(que_id) => {
  try {
    let completed = false;
    let count = 0;
    let taskRes;

    while (!completed && count < 20) {
      await waitFiveSeconds()
      taskRes = await sendBoothAIRequest({
        task: "status",
        data: { que_id },
      });

      if (taskRes.data.status === "complete") {
        completed = true;
      } else {
        count++;
      }
    }

    return completed;    
  } catch (error) {
    return false;
  }
}

export default function SelectAvatar({ handleContinue, sku, userEmail }) {

  const [page, setPage] = React.useState('ChooseAvatar');
  const [isFixed, setIsFixed] = React.useState(true);

  //sticky footer: start
  const offset = () => {
    const carouselHeight = document.querySelector(".thumbs-list2")?.clientHeight;
    const windowHeight = window.innerHeight;
    return carouselHeight - windowHeight + 420;
  };
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsFixed(scrollTop < offset());
  };

  React.useEffect(() => {
    if (avatarArray) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [avatarArray]);
  //sticky footer: end
  

  const getImage = (url) => {
    return url ? url : withPrefix("assets/img/green-little-balls.gif");
  }

  const [promptTxt, setPromptTxt] = React.useState('Jogging in central park, surrounded by trees, athletic photo, cinematic lighting');
  const [avatarUuid, setAvatarUuid] = React.useState();

  const handleSubmit = async () => {
    setPage('BoothImg');

    if (!avatarUuid || !promptTxt) return;

    try {
      const product = await sendBoothAIRequest({
        task: "create_product",
        data: {
          name: "ArtBasel Submission",
          category: "Shirts",
          images: [sku.url],
        }
      });

      const boothImage = await sendBoothAIRequest({
        task: "image-generation",
        data: {
          prompt: promptTxt,
          negative_prompt: "blurry, wrong fingers, illusion, wrong face",
          num_images: 1,
          products_ids: [product.data.product_id],
          avatar_id: avatarUuid,
        }
      });

      const queId = boothImage.data?.[0]?.que_id;
      const imageUrl = boothImage.data?.[0]?.image;

      const completed = await getTaskStatus(queId);

      if (completed) {
        //Need to send the boothAI image to the email address of the user.
        if (userEmail) sendBaselBoothEmail({ email: userEmail, image: imageUrl });

        handleContinue(imageUrl);
      } else {
        setPage('ChooseAvatar');

        //swal popup that says something went wrong and re-try message
        Swal.fire({
          icon: 'error',
          text: 'Oops! Something went wrong. Please try again.',
          confirmButtonText: 'Retry',
        }).then(async(result) => {
          if (result.isConfirmed) handleSubmit();
        })
      }
    } catch (error) {
      console.log(error);
      setPage('ChooseAvatar');

      //swal popup that says something went wrong and re-try message
      Swal.fire({
        icon: 'error',
        text: 'Oops! Something went wrong. Please try again.',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) handleSubmit();
      })
    }
  }

  return (
    <>
      {page === 'ChooseAvatar' ? (
        <div className="container">
          <div className="compare-brand">
            <div className="sub-heading">Introducing:</div>
            <div className="cl-wrap">
              <div className="cl"><img src={withPrefix("assets/img/brand-99-generations.svg")} alt="brand-99-generations" /></div>
              <div className="cl">X</div>
              <div className="cl"><img src={withPrefix("assets/img/brand-booth-ai.svg")} alt="brand-booth-ai" /></div>
            </div>
          </div>
          <div className="txt-lvl">Quick: Let’s set the scene and pick a model!</div>

          <div className="prompt-box">
            <div className="sub-heading">Prompt</div>
            <textarea className="txt-bx" type="text" value={promptTxt} onChange={(e) => setPromptTxt(e.target.value)} name="prompt" placeholder="Describe what you want" rows="10"></textarea>

            <div className="thumbs-list2">
              {
                avatarArray.map((avatar) => (
                  <div key={avatar.uuid} className="article">
                    <a onClick={() => setAvatarUuid(avatar.uuid)} className={`box ${avatar.uuid === avatarUuid ? 'selected' : ''}`}>
                      <div className="image">
                        <ImageItem src={getImage(avatar.headshot_url)} alt={avatar.name} />
                      </div>
                    </a>
                  </div>
                ))
              }
            </div>

            <div className={`sticky ${isFixed ? 'fixed' : ''}`} >
              <div className="my-preview2">
                <div className="ltxt">Wearing</div>
                <div className="article"><a href="#" className="box">
                  <div className="image">
                    <img src={`${sku.url}?version=80`} alt="sp-crop-top" />
                  </div>
                  <p>{sku.label ?? ''}</p>
                </a></div>
              </div>

              <div className="btn-sm">
                <a
                  className={`btn ${(avatarUuid && promptTxt) ? 'btn-blue' : 'btn-gray'}`}
                  onClick={handleSubmit}
                >
                  SUBMIT
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : page === 'BoothImg' ? (
        <div className="container">
          <div className="compare-brand">
            <div className="sub-heading">Introducing:</div>
            <div className="cl-wrap">
              <div className="cl"><img src={withPrefix("assets/img/brand-99-generations.svg")} alt="brand-99-generations" /></div>
              <div className="cl">X</div>
              <div className="cl"><img src={withPrefix("assets/img/brand-booth-ai.svg")} alt="brand-booth-ai" /></div>
            </div>
          </div>

          <div className="loader-img">
            <img src={withPrefix("assets/img/green-little-balls.gif")} alt="page-loader" />
          </div>
          
          <div className="note-box">Please be patient as this can take up to 60 seconds. Do not exit the page. Do not dim your phone.</div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const ImageItem = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  return (
    <div style={{ position: 'relative', paddingTop: '100%' }}>
      <img
        className="merchImages"
        id="merchImages"
        src={src}
        alt={alt}
        style={{
          opacity: imageLoaded ? 1 : 0,
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        onLoad={() => setImageLoaded(true)}
      />
      {!imageLoaded && (
        <img
          src={withPrefix("assets/img/green-little-balls.gif")}
          className="merchImages"
          id="merchImages"
          alt={alt}
          style={{
            opacity: imageLoaded ? 0 : 1,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      )}
    </div>
  )
}