import React, { useEffect, useState, useRef } from "react";
import { withPrefix } from "gatsby";
import { useDispatch } from 'react-redux';
import { setShowProductDetailsModal } from "../../sagas/checkout/checkoutSlice";
import { createTaskWithoutToken, getPrintfulTaskWithoutToken } from "../../services/apiCalls";
import { useMediaQuery } from "@mui/material";

function waitFiveSeconds() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, 5000);
  });
}

const getPrintfulData = async(task_key) => {
  let completed = false;
  let count = 0;
  let printfulRes;

  while (!completed && count < 10) {
    await waitFiveSeconds()
    printfulRes = await getPrintfulTaskWithoutToken(task_key, 'no');
    if (printfulRes.result?.status === "completed") {
      completed = true;
    } else {
      count++;
    }
  }

  return printfulRes?.result?.mockups;
}

export default function SelectMerchProduct({
  productKey,
  productLabel,
  dataId,
  carousel,
  active,
  updateProductsObject,
  logo,
}) {
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);
  const matchesMobile = useMediaQuery("(max-width:500px)");

  const getImage = () => {
    return product?.[0]?.value?.[0] ? `${product[0].value[0]}?version=200` : withPrefix("assets/img/green-little-balls.gif");
  }

  const handleBoxClick = () => {
    if (generatingSku) return;

    if (!product) return;

    dispatch({
      type: setShowProductDetailsModal.type,
      payload: {
        ProductDetailsModalProps: {
          open: true,
          product,
          productKey,
          productLabel,
          dataId,
        }
      },
    });   
  }

  const [generatingSku, setGeneratingSku] = useState(true);
  const handleGenerateClick = async () => {
    setGeneratingSku(true);

    const { carousel_uID, thumbnail, upscaled } = carousel;

    try {
      const taskRes = await createTaskWithoutToken({
        image: upscaled,
        name: productKey,
        logo: logo ?? undefined,
      });

      if (taskRes.status == "success") {
        const { result } = taskRes.data.que;
        const mockups = await getPrintfulData(result.task_key);

        if (mockups?.length) {
          const images = [];
          const placement = [];
          
          mockups.forEach((mockup) => {
            images.push(mockup.mockup_url);
            placement.push(mockup.placement);
          });

          setProduct([{
            placement, value: images
          }]);

          updateProductsObject([{
            placement, value: images, label: productLabel,
          }]);

          let items = JSON.parse(localStorage.getItem("allItems") || "[]");
          items = items.filter((item) => item.key !== dataId);
          items = [...items, { carousel_uID, key: dataId, thumbnail, upscaled, logo, value: { images, placement } }];
          localStorage.setItem("allItems", JSON.stringify(items));
        }
      }      
    } catch (error) {
      console.log(error);
    } finally {
      setGeneratingSku(false);
    }
  }

  //For the first time
  useEffect(() => {
    if (!product && active) {
      handleGenerateClick();
    }
  }, [product, active])

  const boxRef = useRef(null);
  const width = boxRef?.current?.offsetWidth ?? 0;
  const [height, setHeight] = useState();
  useEffect(() => {
    if (!product && width) {
      setHeight(width);
    }
  }, [product, width])

  return (
    <div className="col-md-2">
      <div
        className={`box ${(!product && !generatingSku) ? 'refresh' : ''}`}
        data-id={dataId}
        onClick={handleBoxClick}
        ref={boxRef} 
        style={{ height: height ?? 'unset' }}
      >
        <div className={productKey + " figure"}>
          {product ? (
            <img
              className="merchImages"
              id="merchImages"
              src={getImage()}
              alt={productLabel}
            />
          ) : generatingSku ? (
            <img
              className="merchImages"
              id="merchImages"
              src={withPrefix("assets/img/green-little-balls.gif")}
              alt={productLabel}
            />
          ) : (
            <div className="refresh-btn-container" style={{ display: 'inline-block' }}>
              <div className="txt-max">
                <div className="icon-info">
                  <img src={withPrefix("assets/img/icon-info-lrg.png")} alt="icon-info-lrg" />
                </div>
                {/* this is temporal solution */}
                {!!logo ? (
                  <>
                    <p style={{ margin: '1rem' }}>{productLabel}</p>
                    <p style={{ margin: '1rem' }}>Comming Soon</p>                  
                  </>
                ) : (
                  <>
                    <p style={{ margin: matchesMobile ? 0: '1rem' }}>something went wrong, <br /> please try again.</p>
                    <button 
                      className="btn btn-blue btn-refresh" 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleGenerateClick();
                      }}
                    >Refresh</button>  
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        {product && (
          <>
            <div className="aside1">
              <a className="tool-tip">
                {productLabel}
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
