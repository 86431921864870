import * as React from "react";
import { withPrefix } from "gatsby";

export default function TryAIMagic({ handleContinue, generatedSku }) {
  //sticky footer: start
  const [isFixed, setIsFixed] = React.useState(true);

  const offset = () => {
    const carouselHeight = document.querySelector(".article-wrap").clientHeight;
    const windowHeight = window.innerHeight;
    return carouselHeight - windowHeight + 260;
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsFixed(scrollTop < offset());
  };

  React.useEffect(() => {
    if (generatedSku) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [generatedSku]);
  //sticky footer: end

  const getImage = (url) => {
    return url ? `${url}?version=184` : withPrefix("assets/img/green-little-balls.gif");
  }

  const [selectedIndex, setSelectedIndex] = React.useState();
  const [selectedSku, setSelectedSku] = React.useState();
  const handleSelectSku = (skuName, index) => {
    console.log(generatedSku[skuName])

    setSelectedIndex(index);
    setSelectedSku({
      url: generatedSku[skuName]?.[0]?.value?.[0],
      label: generatedSku[skuName]?.[0]?.label,
    });
  }

  const handleTryAIMagic = () => {
    if (selectedSku) handleContinue(selectedSku);
  }

  return (
    <div className="container">

      <div className="sub-heading">Unlock Some Extra Magic!</div>

      <div className="note-box2">
        <img src={withPrefix("assets/img/icon-info.svg")} alt="icon-info" />
        Select one item to make the magic happen!
      </div>

      <div className="thumbs-list">
        <div className="article-wrap">
          {Object.keys(generatedSku).map((skuName, i) => (
            <div key={i} className="article">
              <a onClick={() => handleSelectSku(skuName, i)} className={`box ${i === selectedIndex ? 'active' : ''}`}>
                <div className="image">
                  <ImageItem src={getImage(generatedSku[skuName]?.[0]?.value?.[0])} alt={skuName} />
                </div>
                <p>{generatedSku[skuName]?.[0]?.label}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
      
      <div className={`tryBtn ${isFixed ? 'fixed' : ''}`} >
        <div className="btn-sm">
          <a 
            className={`btn ${selectedIndex !== undefined ? 'btn-purple':'btn-gray'}`}
            onClick={handleTryAIMagic}
          >
            <span>TRY AI MAGIC
              <img src={withPrefix('assets/img/icon-aistick.svg')} alt="icon-aistick" />
            </span>
          </a>
        </div>
      </div>
    </div>
  )
}

const ImageItem = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  return (
    <div style={{ position: 'relative', paddingTop: '100%' }}>
      <img 
        className="merchImages"
        id="merchImages"
        src={src}
        alt={alt}
        style={{ 
          opacity: imageLoaded ? 1: 0, 
          position: 'absolute',
          top: 0,
          left: 0,
        }} 
        onLoad={() => setImageLoaded(true)} 
      />
      {!imageLoaded && (
        <img 
          src={withPrefix("assets/img/green-little-balls.gif")} 
          className="merchImages"
          id="merchImages"
          alt={alt}
          style={{ 
            opacity: imageLoaded ? 0: 1, 
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      )}
    </div>
  )
}
