import * as React from "react";
import Header from "../components/quick/header";
import Footer from "../components/quick/footer";
import '../components/quick/quick.scss';
import UploadArt from "../components/quick/uploadArt";
import SecurePreview from "../components/quick/securePreview";
import TryAIMagic from "../components/quick/tryAIMagic";
import SelectAvatar from "../components/quick/selectAvatar.jsx";
import Final from "../components/quick/final.jsx";
import { useMediaQuery } from "@mui/material";
import { navigate } from "gatsby";

export default function Quick() {
  const matchesMobile = useMediaQuery("(max-width:600px)");

  React.useEffect(() => {
    if (!matchesMobile) navigate('/');
  }, [matchesMobile])

  const [step, setStep] = React.useState('UploadYourArt');
  const [carousel, setCarousel] = React.useState();
  const [generatedSku, setGeneratedSku] = React.useState();
  const [sku, setSku] = React.useState();
  const [boothImage, setBoothImage] = React.useState();
  const [userEmail, setUserEmail] = React.useState();

  return (
    <>
      {matchesMobile ? (
        <div className="container-main quick-art-basel" id="page">
          <Header />

          <main className="content-sm">

            {step === 'UploadYourArt' ? (
              <UploadArt handleChangeStep={() => setStep('SecurePreview')} />
            ) : step === 'SecurePreview' ? (
              <SecurePreview 
                handleContinue={(email, generatedSku, carousel) => {
                  setUserEmail(email);
                  setGeneratedSku(generatedSku);
                  setCarousel(carousel);
                  setStep('TryAIMagic');
                }}
              />
            ) : step === 'TryAIMagic' ? (
              <TryAIMagic 
                handleContinue={(sku) => {
                  setSku(sku);
                  setStep('SelectAvatar');
                }} 
                generatedSku={generatedSku}
              />
            ) : step === 'SelectAvatar' ? (
              <SelectAvatar 
                handleContinue={(image) => {
                  setBoothImage(image);
                  setStep('Final');
                }} 
                sku={sku}
                userEmail={userEmail}
              />
            ) : step === 'Final' ? (
              <Final 
                boothImage={boothImage}
                userEmail={userEmail}
                carousel={carousel}
                generatedSku={generatedSku}
              />
            ) : (
              <></>
            )}

          </main>

          <Footer />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
