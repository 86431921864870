import * as React from 'react';
import { useState, useEffect } from "react";
import { withPrefix } from "gatsby";
import StepBar from "./stepBar";
import { useDropzone } from 'react-dropzone';
import CropImageModal from '../CropImageModal';
import { useDispatch, useSelector } from 'react-redux';
import { upscaleImage, resetUpscaleImage, selectCarouselsState } from "../../sagas/carousels/carouselsSlice";
import { postImage, selectImageState } from "../../sagas/image/imageSlice";
import Swal from 'sweetalert2';

const styles = {
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '18px',
    color: '#10100a',
    width: '100%',
    height: '114px',
    marginBottom: '16px',
  },
  dropzoneText: {
    color: '#000000',
    fontFamily: 'Albert Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    paddingBottom: 5,
    marginBottom: 0,
  },
  thumbsContainer: {
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  thumb: {
    width: '70vw',
    height: '70vw',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '15px',
    boxShadow: 'rgba(87, 87, 225, 0.35) 0 5px 10px',
    position: 'relative',
    margin: '8px 0 0 0',
  },
  thumbInner: {
    width: '100%',
    float: 'left',
    textSlign: 'center',
    borderRadius: '15px',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    borderRadius: '15px',
    height: '100%',
    objectFit: 'cover',
  },
  iconCloseBox: {
    width: '42px',
    height: '42px',
    float: 'right',
    position: 'absolute',
    top: '-16px',
    right: '-16px',
  },
  iconClose: {
    width: '42px',
    height: '42px',
    float: 'left',
    fontWeight: 'inherit',
    fontSize: '24px',
    color: '#ffffff',
    lineHeight: '42px',
    textDecoration: 'none',
    textAlign: 'center',
    background: '#FF3333',
    borderRadius: '360px',
  },
  customStyles: {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      width: 400,
      borderRadius: "20px",
      fontFamily: `"Albert Sans", sans-serif`,
      padding: 24,
    },
  },
  browseBtn: {
    border: '1px solid #10100A',
    borderRadius: '8px',
    padding: '16px 24px',
    color: '#595A5C',
    background: 'transparent',
    textTransform: 'uppercase',
    fontFamily: 'Roboto Mono',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: 16,
  },
  kyaText: {
    fontFamily: 'Roboto Mono',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '28px',
    color: '#10100A',
  },
  kyaShareLinkText: {
    marginTop: 10,
    fontFamily: 'Albert Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#8F0CED',
  },
  kyaApplyTYText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#595A5C',
  },
  input: {
    height: '56px',
    fontSize: '16px',
    marginBottom: '8px',
    background: 'transparent',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}

export default function UploadArt({ handleChangeStep }) {
  const dispatch = useDispatch();
  const { isUpscalingImageSuccess, isUpscalingImageFailed, UpscaleImageResponseDto } = useSelector(selectCarouselsState);
  const { isPostingImageSuccess, PostImageResponse } = useSelector(selectImageState);

  const [page, setPage] = React.useState('ReadyToUpload');

  const [isDragActive, setIsDragActive] = useState(false);

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      // 'image/*': ['.jpeg', '.png', '.jpg', '.webp', '.heic', '.heif']
      'image/*': ['.jpeg', '.png', '.jpg', '.webp']
    },
    onDrop: acceptedFiles => {
      handleImage(acceptedFiles);
    },
    maxFiles: 1,
  });

  const handleImage = async (files) => {
    if (!files || files.length == 0) {
      return;
    }
    const file = files[0];

    if (!file) return;

    if (file.type === 'image/heif' || file.type === 'image/heic') {
      // const convertedData = await convertHeicToPng(file);
      // setPicture(convertedData);
      // setPreviewImage(convertedData);
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      await new Promise(resolve => reader.onload = resolve);
      const base64data = reader.result;
      setPicture(reader.result);
      setPreviewImage(base64data);
      setPage('UploadPreview');
    }
  };

  const [previewImage, setPreviewImage] = React.useState("");

  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [cropper, setCropper] = useState(<any />);
  const [picture, setPicture] = useState("");

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      uploadCroppedImg(cropper.getCroppedCanvas().toDataURL());
      setCropModalOpen(false);
    }
  };
  const uploadCroppedImg = (base64data) => {
    var real_str = base64data.slice(22);
    setPage('UploadingImage');

    dispatch({
      type: postImage.type,
      payload: { base64Url: real_str },
    });
  };

  useEffect(() => {
    if (isPostingImageSuccess) {
      UpscaleImage(PostImageResponse.url);
    }
  }, [isPostingImageSuccess, PostImageResponse])

  const [selectedImage, setSelectedImage] = React.useState();
  async function UpscaleImage(image) {
    setSelectedImage(image);
    setPage('UploadingImage');
    dispatch({
      type: upscaleImage.type,
      payload: { images: [image] }
    });
  }

  useEffect(() => {
    if (isUpscalingImageSuccess) {
      // const merchifyArr = [];

      // merchifyArr.push({
      //   id_val: 0,
      //   original: selectedImage,
      //   upscaled: UpscaleImageResponseDto.results[0].upscaled,
      // });

      // localStorage.setItem("mergify", JSON.stringify(merchifyArr));
      // localStorage.setItem("job_id", ",,,,,");
  
      // setCheckoutState({ ...checkoutState, flow: 'upload' });

      // dispatch(resetUpscaleImage());
      // navigate("/select-merch");


      handleChangeStep();
    } else if (isUpscalingImageFailed) {

      setPage('UploadPreview');

      dispatch(resetUpscaleImage());
      //swal popup that says something went wrong and re-try message
      Swal.fire({
        icon: 'error',
        text: 'Oops! Something went wrong. Please try again.',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) UpscaleImage(selectedImage);
      })
    }
  }, [isUpscalingImageSuccess, isUpscalingImageFailed, UpscaleImageResponseDto])

  return (
    <>
      {page === 'ReadyToUpload' ? (
        <div className="container">

          {/* <div className="banner-header"><img src={withPrefix("assets/img/banner-art.png")} alt="banner-art" /></div> */}

          <StepBar step='UploadYourArt' />

          <div className="upload-box">
            <div className="sub-heading"><strong>Upload</strong></div>

            <div className="upload-front">
              <div 
                {...getRootProps({ })} 
                style={{ ...styles.dropzone, border: `1px dashed ${isDragActive ? '#A020F0' : '#000000'}` }}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
              >
                <input {...getInputProps()} />
                <img src={withPrefix("assets/img/file-upload.svg")} alt="upload-icon" />
              </div>

              <p>By clicking "Upload" I'm agreeing to the site-wide <a href="#">content policy</a>.</p>
            </div>

            <div className="btn-out"><a href="javascript:;" className="btn disabled">Upload</a></div>
          </div>

          <div className="divider-box"></div>

          <div className="notify-box">
            <p>Hello there!</p>
            <p><strong>You've just unlocked a special page by scanning one of our exclusive QR codes! Way to go!</strong></p>
            <div className="image-txt">
              <div className="image"><img src={withPrefix("assets/img/photo-01.png")} alt="photo-01" /></div>
              <div className="txt">Please begin by uploading a photo of your favorite artwork.</div>
            </div>
            <p>Get ready for a magical moment – it's just 60 seconds away!</p>
            <p>Don’t worry, your art will not be shared with anyone.</p>
            <p>This is a completely private experience.</p>
          </div>

        </div>
      ) : page === 'UploadPreview' ? (
        <div className="container">
          <div className="banner-header"><img src={withPrefix("assets/img/banner-art.png")} alt="banner-art" /></div>
  
          <StepBar step='UploadYourArt' />
  
          <div className="upload-box">
            <div className="sub-heading"><strong>Upload</strong></div>

            {previewImage && (
              <div style={styles.thumbsContainer}>
                <div style={styles.thumb}>
                  <div style={styles.thumbInner}>
                    <img src={previewImage} style={styles.img} alt="preview" />
                  </div>
                  <div style={styles.iconCloseBox}>
                    <a 
                      onClick={() => {
                        setPreviewImage('');
                        setPage('ReadyToUpload');
                      }} 
                      className="far fa-times" 
                      style={styles.iconClose}
                    ></a>
                  </div>
                </div>
              </div>
            )}

            <div className="btn-out">
              <a href="javascript:;" className="btn" onClick={() => setCropModalOpen(true)}>Upload</a>
            </div>
          </div>

          <CropImageModal 
            cropModalOpen={cropModalOpen} 
            setCropModalOpen={setCropModalOpen} 
            picture={picture}
            cropper={cropper} 
            setCropper={setCropper}
            getCropData={getCropData}
          />
  
        </div>
      ) : page === 'UploadingImage' ? (
        <div className="container">	
          <StepBar step='UploadYourArt' />
          
          <div className="loader-img">
            <img src={withPrefix("assets/img/green-little-balls.gif")} alt="page-loader" />
          </div>
          
          <div className="note-box">Please be patient as this can take up to 60 seconds. Do not exit the page. Do not dim your phone.</div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
