import * as React from 'react';
import { withPrefix } from "gatsby";

export default function Footer() {
    return (
        <footer className="footer-sm">
            <div className="container">

                <div 
                    className="pre-foot"
                    style={{ background: `url(${withPrefix('assets/img/icon-robot2.svg')}) no-repeat bottom right` }}
                >
                    <div className="brand"><a href="index.html" title="99 Generations"><img src={withPrefix("assets/img/brand-99-generations.svg")}
                        alt="brand-99-generations" /></a></div>
                    <div className="icons"><img src={withPrefix("assets/img/icons-ai.svg")} alt="icons-ai" /></div>
                </div>

                <div className="copy-rights">
                    <div className="foot-menu">
                        <ul>
                            <li><a href="privacy.html">Privacy Policy</a></li>
                            <li><a href="terms.html">Terms of Service</a></li>
                            <li><a href="cookie-policy.html">Cookies Settings</a></li>
                        </ul>
                    </div>

                    <p>&copy;2022</p>
                </div>

            </div>
        </footer>
    )
}
