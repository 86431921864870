import * as React from "react";
import { withPrefix } from "gatsby";
import StepBar from "./stepBar";
import { useSelector } from 'react-redux';
import { selectCarouselsState } from "../../sagas/carousels/carouselsSlice";
import { generateUniqueCarouselUID } from "../../services/utils";
import SelectMerchCarousel from "./select-merch-carousel";
import ProductDetailsModal from "../MyCreationsView/ProductDetailsModal";
import { sendBaselUserEmail } from "../../services/apiCalls";
import Dialog from '@mui/material/Dialog';

export default function SecurePreview({ handleContinue }) {
  const { UpscaleImageResponseDto } = useSelector(selectCarouselsState);

  const [thumbnail, setThumbnail] = React.useState('');
  const [carousel, setCarousel] = React.useState(null);


  React.useEffect(() => {
    if (UpscaleImageResponseDto?.results?.[0]?.original && UpscaleImageResponseDto?.results?.[0]?.upscaled) {
      setThumbnail(UpscaleImageResponseDto.results[0].original);
      setCarousel({
        thumbnail: UpscaleImageResponseDto.results[0].original,
        upscaled: UpscaleImageResponseDto.results[0].upscaled,
        carousel_uID: generateUniqueCarouselUID(),
      });
    }
  }, [UpscaleImageResponseDto])


  //sticky footer: start
  const [isFixed, setIsFixed] = React.useState(true);

  const offset = () => {
    const carouselHeight = document.querySelector(".select-merch").clientHeight;
    const windowHeight = window.innerHeight;
    return carouselHeight - windowHeight + 250;
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsFixed(scrollTop < offset());
  };

  React.useEffect(() => {
    if (carousel) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [carousel]);
  //sticky footer: end


  //Email ME: start
  const [openEmailMeModal, setOpenEmailMeModal] = React.useState(false);
  const [openCongratsModal, setOpenCongratsModal] = React.useState(false);
  const [email, setEmail] = React.useState();

  const [generatedSku, setGeneratedSku] = React.useState();
  const [registeredUserId, setRegisteredUserId] = React.useState();

  const sendEmail = async () => {
    const emailRegex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/;

    if (email && emailRegex.test(email)) {
      setOpenEmailMeModal(false);
      setOpenCongratsModal(true);

      const response = await sendBaselUserEmail({
        email : email,
        source : 'Quick', 
      });

      setRegisteredUserId(response.userId);
    }
  }
  //Email ME: end

  //Skip Email: start
  const skipEmail = () => {
    setOpenEmailMeModal(false);
    setOpenCongratsModal(true);
  }
  //Skip: end

  const goToTryAIMagic = () => {
    setOpenCongratsModal(false);
    handleContinue(email, generatedSku, carousel);
  }

  const [showEmailMeButton, setShowEmailMeButton] = React.useState(false);

  return (
    <div className="container">
      <StepBar step='SecurePreview' />

      <div className="headtxt-wrap">
        <div className="ltxt">Secure Preview</div>
        <div className="rtxt">{carousel?.carousel_uID && ''}</div>
      </div>

      <div className="select-feature-list" style={{ padding: 0 }}>
        <div className="select-merch">
          {carousel && (
            <SelectMerchCarousel
              carousel={carousel}
              active={true}
              handleShowNextButton={(show) => setShowEmailMeButton(show)}
              sendGeneratedSku={(generatedSku) => setGeneratedSku(generatedSku)} 
              registeredUserId={registeredUserId}
            />
          )}
        </div>
      </div>

      <div className={`my-preview ${isFixed ? 'fixed' : ''}`}>
        <p>My Preview:</p>
        <div className="image-wrap">
          <div className="image"><img src={thumbnail ? `${thumbnail}?version=90` : withPrefix("assets/img/sp-placeholder.png")} alt="sp-placeholder" /></div>

          {showEmailMeButton && (
            <div className="btn-out">
              <a className="btn btn-blue"
                onClick={() => setOpenEmailMeModal(true)}
              >SIGN UP</a>
            </div>
          )}
        </div>
      </div>

      <ProductDetailsModal hideButtons={true} />

      <Dialog onClose={() => setOpenEmailMeModal(false)} open={openEmailMeModal}>
        <div className="quick-art-basel">
          <div className="modal submit-image-modal" id="submit-image" role="dialog" onClick={() => setOpenEmailMeModal(false)}>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>

                <div
                  className="close"
                  onClick={() => setOpenEmailMeModal(false)}
                  style={{ background: `url(${withPrefix('assets/img/icon-close.svg')})` }}
                >Close</div>

                <div className="heading-top">
                  <h2>You’re almost done!</h2>
                  <p>Let’s save your creation, we will e-mail you.</p>
                </div>

                <div className="submit-form">
                  <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="search-c" id="search-c" placeholder="Enter E-mail" />
                  <input
                    type="submit" id="submit-c" className="btn btn-blue open-submit-image2"
                    value="Submit"
                    onClick={sendEmail}
                  />
                  <input
                    type="submit" id="submit-c" className="btn open-submit-image2"
                    value="Skip"
                    onClick={skipEmail}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog onClose={goToTryAIMagic} open={openCongratsModal}>
        <div className="quick-art-basel">
          <div className="modal submit-image-modal" id="submit-image2" role="dialog" onClick={goToTryAIMagic}>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>

                <div
                  className="close"
                  onClick={goToTryAIMagic}
                  style={{ background: `url(${withPrefix('assets/img/icon-close.svg')})` }}
                >Close Modal</div>

                <div className="heading-top">
                  <h2>Congrats!</h2>
                  <p>Please check your e-mail as we have sent you a recap of your experience today.</p>
                </div>

                <div className="ai-image"><img src={withPrefix("assets/img/ai-frame.png")} alt="ai-frame" /></div>

                <div className="sml-txt">
                  <p>Want to see something special? <br /> See your product come to life with AI</p>
                </div>

                <div className="btn-sm">
                  <a 
                    href="#" className="btn btn-purple"
                    onClick={goToTryAIMagic}
                  >
                    <span
                      className="inline"
                      style={{ background: `url(${withPrefix('assets/img/icon-aistick.svg')}) no-repeat right center` }}
                    >TRY AI MAGIC</span>
                  </a>
                </div>

                <div className="close-txt" onClick={goToTryAIMagic}>Close</div>

              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
